<template>
  <div class="product-favorite-profile">
    <router-link
      :to="{
        name: 'ProductDetail',
        params: { url: product.url.replace('product-', '') },
        query: { color: product.color },
      }"
    >
      <img :src="product.imagenes[0]" :alt="product.producto_titulo" />
    </router-link>
    <div class="container-data-favorite-profile col-sm pt-sm-2">
      <router-link
        :to="{
          name: 'ProductDetail',
          params: { url: product.url.replace('product-', '') },
          query: { color: product.color },
        }"
      >
        <p class="title-favorite">{{ product.producto_titulo }}</p>
      </router-link>
      <span class="title-sk-color-favorite"
        >SKU: {{ product.producto_sku }} / Color: {{ product.color }}</span
      >
      <p class="mt-2 d-block d-lg-none px-0 price-product-favorite">
        ${{ new Intl.NumberFormat('de-DE').format(product.producto_precio) }}
      </p>
      <div v-if="product.tallas.length > 1" class="box-size py-3">
        <div class="col-md d-flex flex-wrap">
          <div
            v-for="(size, index1) in product.tallas"
            :key="index1"
            :class="
              size.stock
                ? sizeSelect == size.id
                  ? 'size-select'
                  : ''
                : 'size-disabled'
            "
            class="container-size"
            @click="size.stock > 0 ? (sizeSelect = size.id) : ''"
          >
            {{ size.name }}
          </div>
        </div>
      </div>
      <div class="delete-product-movil">
        <span @click="deleteProductFav(product.producto_sku, product.color)"
          >Eliminar</span
        >
      </div>
    </div>
    <div class="m-auto d-none d-lg-block">
      <span class="price-product-favorite"
        >${{
          new Intl.NumberFormat('de-DE').format(product.producto_precio)
        }}</span
      >
    </div>
    <div class="buttons-add-cart my-auto mx-4">
      <button
        class="bnt-primary w-100"
        :style="sizeSelect ? '' : 'opacity: .4'"
        :disabled="!sizeSelect"
        @click="
          sendSoliAddProduct({
            sku: product.producto_sku,
            color: product.color,
            tallaId: sizeSelect,
            data: product,
          })
        "
      >
        Agregar al carrito
      </button>
    </div>
    <img
      src="@/assets/icons/close-modal.svg"
      alt="close img"
      class="delete-product-fav"
      @click="deleteProductFav(product.producto_sku, product.color)"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { sendFBQEvent } from '@/fbq'
export default {
  props: {
    product: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      sizeSelect: '',
    }
  },
  computed: {
    ...mapState('cart', ['errorAddToCart']),
  },
  mounted() {
    if (this.product.tallas.length == 1 && this.product.tallas[0].stock > 0) {
      this.sizeSelect = this.product.tallas[0].id
    }
  },
  methods: {
    ...mapActions('favorite', ['deleteProductToFavorite']),
    ...mapActions('cart', ['addProductTocart']),
    async deleteProductFav(sku, color) {
      let data = {
        sku: sku,
        color: color,
      }
      await this.deleteProductToFavorite(data)
    },
    async sendSoliAddProduct(data) {
      await this.addProductTocart(data).then(() => {
        if (!this.errorAddToCart) {
          this.$bvModal.show('modal-add-to-cart')
          sendFBQEvent(this, 'AddToCart', {
            product_id: data.producto_sku,
            value: data.precio,
            content_type: 'product',
            content_name: data.categoria_slug.toUpperCase() + ' CLOVER',
            content_category: data.categoria_slug.toUpperCase(),
            content_ids: data.producto_sku + data.color + data.tallaId,
            currency: 'COP',
          })
        }
      })
    },
  },
}
</script>
