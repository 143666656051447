<template>
  <div class="component-profile">
    <div
      v-if="productsFavorite && productsFavorite.length > 0"
      class="d-block mb-auto w-100"
    >
      <h2 class="mb-5 text-center">
        Mis favoritos
        <span style="font-size: 17px; padding-left: 1rem">{{
          productsFavorite && productsFavorite.length > 1
            ? productsFavorite.length + ' Productos'
            : '1 Producto'
        }}</span>
      </h2>
      <product-favorite
        v-for="(product, index) in productsFavorite"
        :key="index"
        :product="product"
      />
    </div>
    <div v-else class="text-center">
      <h2 class="px-4">Aún no tienes productos favoritos</h2>
      <p class="px-4" style="max-width: 700px">
        Cuando agregues productos a tus favoritos los podrás ver en este
        espacio, recuerda que para añadir los productos a favoritos debes dar
        click al boton con un corazon.
      </p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="mb-4"
        width="20"
        height="18.465"
        viewBox="0 0 20 18.465"
        @click="click = !click"
      >
        <path
          id="Trazado_6615"
          :fill="click ? '#F6BDC8' : '#d5d5d5'"
          data-name="Trazado 6615"
          d="M232.886,110.515a5.963,5.963,0,0,0,1.6,4.082l0,0,.351.351,7.665,7.665.394.394.395-.4,8.107-8.126.027-.026a6,6,0,0,0-.119-7.98v-.007l-.163-.164a6,6,0,0,0-8.245-.2,5.995,5.995,0,0,0-8.269.176l-.163.164v.007a5.962,5.962,0,0,0-1.581,4.057"
          transform="translate(-232.886 -104.544)"
        />
      </svg>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import ProductFavorite from '@/components/profile/ProductFavorite.vue'
export default {
  components: {
    ProductFavorite,
  },
  data() {
    return {
      click: false,
    }
  },
  computed: {
    ...mapState('favorite', ['productsFavorite']),
  },
}
</script>
