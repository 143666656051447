<template>
  <div>
    <base-profile />
    <div class="container-profile pb-5">
      <profile-mis-favoritos />
    </div>
  </div>
</template>
<script>
import BaseProfile from '@/components/profile/BaseProfile'
import ProfileMisFavoritos from '@/components/profile/ProfileMisFavoritos'
export default {
  components: { BaseProfile, ProfileMisFavoritos },
  // mounted() {
  //     document.title = 'Perfil | DEREK Tienda Online'
  //     this.$gtag.pageview(this.$route.fullPath);
  // },
}
</script>
