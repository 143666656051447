<template>
  <div class="d-block d-md-none my-4">
    <carousel
      class="col-12 px-0 mx-0 carousel-menu-profile"
      :dots="false"
      :lazy-load="true"
      :nav="false"
      :responsive="{
        0: { items: 2, nav: false, slideBy: 2, stagePadding: 30 },
      }"
    >
      <div class="item-menu-profile">
        <router-link
          v-slot="{ href, navigate, isExactActive }"
          :to="{ name: 'Profile' }"
        >
          <a :href="href" @click="navigate">
            <span :class="[isExactActive && 'text-item-menu-profile']"
              >Datos</span
            >
          </a>
        </router-link>
      </div>
      <div class="item-menu-profile">
        <router-link
          v-slot="{ href, navigate, isExactActive }"
          :to="{ name: 'ProfileAddress' }"
        >
          <a :href="href" @click="navigate">
            <span :class="[isExactActive && 'text-item-menu-profile']"
              >Direcciones</span
            >
          </a>
        </router-link>
      </div>
      <div class="item-menu-profile">
        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'CambiarClave' }"
        >
          <a
            :href="href"
            :class="[isActive && 'item-menu-profile-select']"
            @click="navigate"
          >
            <span :class="[isActive && 'text-item-menu-profile']"
              >Cambiar clave</span
            >
          </a>
        </router-link>
      </div>
      <div class="item-menu-profile">
        <router-link
          v-slot="{ href, navigate, isExactActive }"
          :to="{ name: 'ProfileOrders' }"
        >
          <a :href="href" @click="navigate">
            <span :class="[isExactActive && 'text-item-menu-profile']"
              >Pedidos</span
            >
          </a>
        </router-link>
      </div>
      <div class="item-menu-profile">
        <router-link
          v-slot="{ href, navigate, isExactActive }"
          :to="{ name: 'PerfilFavorites' }"
        >
          <a :href="href" @click="navigate">
            <span :class="[isExactActive && 'text-item-menu-profile']"
              >Favoritos</span
            >
          </a>
        </router-link>
      </div>
      <div class="item-menu-profile">
        <router-link
          v-slot="{ href, navigate, isExactActive }"
          :to="{ name: 'PerfilCredit' }"
        >
          <a :href="href" @click="navigate">
            <span :class="[isExactActive && 'text-item-menu-profile']"
              >Mi credito</span
            >
          </a>
        </router-link>
      </div>
    </carousel>
  </div>
</template>
<script>
import carousel from 'v-owl-carousel'
export default {
  components: { carousel },
}
</script>
