var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-favorite-profile"},[_c('router-link',{attrs:{"to":{
      name: 'ProductDetail',
      params: { url: _vm.product.url.replace('product-', '') },
      query: { color: _vm.product.color },
    }}},[_c('img',{attrs:{"src":_vm.product.imagenes[0],"alt":_vm.product.producto_titulo}})]),_c('div',{staticClass:"container-data-favorite-profile col-sm pt-sm-2"},[_c('router-link',{attrs:{"to":{
        name: 'ProductDetail',
        params: { url: _vm.product.url.replace('product-', '') },
        query: { color: _vm.product.color },
      }}},[_c('p',{staticClass:"title-favorite"},[_vm._v(_vm._s(_vm.product.producto_titulo))])]),_c('span',{staticClass:"title-sk-color-favorite"},[_vm._v("SKU: "+_vm._s(_vm.product.producto_sku)+" / Color: "+_vm._s(_vm.product.color))]),_c('p',{staticClass:"mt-2 d-block d-lg-none px-0 price-product-favorite"},[_vm._v(" $"+_vm._s(new Intl.NumberFormat('de-DE').format(_vm.product.producto_precio))+" ")]),(_vm.product.tallas.length > 1)?_c('div',{staticClass:"box-size py-3"},[_c('div',{staticClass:"col-md d-flex flex-wrap"},_vm._l((_vm.product.tallas),function(size,index1){return _c('div',{key:index1,staticClass:"container-size",class:size.stock
              ? _vm.sizeSelect == size.id
                ? 'size-select'
                : ''
              : 'size-disabled',on:{"click":function($event){size.stock > 0 ? (_vm.sizeSelect = size.id) : ''}}},[_vm._v(" "+_vm._s(size.name)+" ")])}),0)]):_vm._e(),_c('div',{staticClass:"delete-product-movil"},[_c('span',{on:{"click":function($event){return _vm.deleteProductFav(_vm.product.producto_sku, _vm.product.color)}}},[_vm._v("Eliminar")])])],1),_c('div',{staticClass:"m-auto d-none d-lg-block"},[_c('span',{staticClass:"price-product-favorite"},[_vm._v("$"+_vm._s(new Intl.NumberFormat('de-DE').format(_vm.product.producto_precio)))])]),_c('div',{staticClass:"buttons-add-cart my-auto mx-4"},[_c('button',{staticClass:"bnt-primary w-100",style:(_vm.sizeSelect ? '' : 'opacity: .4'),attrs:{"disabled":!_vm.sizeSelect},on:{"click":function($event){return _vm.sendSoliAddProduct({
          sku: _vm.product.producto_sku,
          color: _vm.product.color,
          tallaId: _vm.sizeSelect,
          data: _vm.product,
        })}}},[_vm._v(" Agregar al carrito ")])]),_c('img',{staticClass:"delete-product-fav",attrs:{"src":require("@/assets/icons/close-modal.svg"),"alt":"close img"},on:{"click":function($event){return _vm.deleteProductFav(_vm.product.producto_sku, _vm.product.color)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }