<template>
  <div>
    <div class="container-menu-nav-profile" :class="dataUser ? '' : 'pt-3'">
      <menu-profile-desktop />
      <menu-profile-movil />
    </div>
  </div>
</template>
<script>
import MenuProfileDesktop from '@/components/profile/MenuProfile/MenuProfileDesktop'
import MenuProfileMovil from '@/components/profile/MenuProfile/MenuProfileMovil'

import { mapState } from 'vuex'
export default {
  components: { MenuProfileDesktop, MenuProfileMovil },
  computed: {
    ...mapState('user', ['dataUser']),
  },
}
</script>
